<template>
    <v-card class="text-center" :loading="loading" elevation="0" outlined>
        <v-card-title class="justify-center" v-text="title"/>
        <v-card-text>
            <span class="card-value" v-text="statistics[type]"/>
            <span class="card-label" v-text="` / ${planLimit} ${label}`"/>
        </v-card-text>
    </v-card>
</template>


<script>
export default {
    name: 'CardStatistic',
    props: {
        title: {
            type: String,
            required: true
        },
        statistics: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        label() {
            switch (this.type) {
                case 'minutes':
                    return 'minutes';
                case 'totalAmount':
                    return 'files';
                case 'fileSize':
                    return 'Mb'
            }
            return '';
        },
        planLimit() {
            switch (this.type) {
                case 'minutes':
                    return this.currentPlan.conversionMinutes || '';
                case 'totalAmount':
                case 'fileSize':
                default:
                    return '';
            }
        }
    },
    data() {
        return {
            currentPlan: {}
        }
    },
    mounted() {
        this.currentPlan = this.$auth.user()?.current_plan?.restrictions || {};
    }
}
</script>

<style lang="scss" scoped>
.v-card__title {
    font-size: 1rem;
}
.card-value {
    font-size: 3rem;
    line-height: 5rem;
}
</style>
