<template>
    <user-dashboard-layout>
        <v-row class="dashboard_cards mt-4">
            <v-col>
                <card-statistic
                    title="Conversion time"
                    :statistics="statistics"
                    type="minutes"
                    :loading="loading"/>
            </v-col>
            <v-col>
                <card-statistic
                    title="Converted files"
                    :statistics="statistics"
                    type="totalAmount"
                    :loading="loading"/>
            </v-col>
            <v-col>
                <card-statistic
                    title="Converted files size"
                    :statistics="statistics"
                    type="fileSize"
                    :loading="loading"/>
            </v-col>
        </v-row>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../layouts/UserDashboardLayout";
import Api from "../../api/Api";
import CardStatistic from "../../components/dashboard/CardStatistic";

export default {
    name: 'DashboardIndex',
    components: { CardStatistic, UserDashboardLayout },
    data() {
        return {
            statistics: {},
            loading: false
        }
    },
    mounted() {
        this.getAnalytics();
    },
    methods: {
        async getAnalytics() {
            this.loading = true;
            this.statistics = await Api.userAnalytics();
            this.loading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.dashboard_cards {

}
</style>
